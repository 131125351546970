// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Fab,
//   FormControlLabel,
//   Button,
//   Checkbox,
//   TextField,
//   Grid,
//   Dialog,
//   DialogActions,
//   InputLabel,
//   Select,
//   DialogTitle,
//   Typography,
//   DialogContent,
//   Divider,
//   Autocomplete,
// } from "@mui/material";

// import { usePostIndividualsMutation, useGetCountriesQuery } from "state/api";

// import MenuItem from "@mui/material/MenuItem";
// import { countryNames, businessActivityScores } from "data/data";
// const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
// const userId = storedValue?._id;

// const countryMenuItems = countryNames.map((country) => (
//   <MenuItem value={`${country}`} key={country}>
//     {country}
//   </MenuItem>
// ));

// const businessActivityMenuItems = businessActivityScores.map((activity) => (
//   <MenuItem value={`${activity.Name}`} key={activity.Name}>
//     {activity.Name}
//   </MenuItem>
// ));

// const AddCustomer = ({ open, onClose }) => {
//   const [postIndividual] = usePostIndividualsMutation();
//   const [fields, setFields] = useState([]);

//   const handleFieldChange = (key, value) => {
//     const updatedFields = fields.map((fields) => ([key] = value));
//     console.log(":" + key + ":" + value);
//     setFields(updatedFields);
//   };

//   const [customerData, setCustomerData] = useState({
//     fullName: "",
//     idType: "",
//     country: "",
//     number: "",
//     issueDate: "",
//     expiryDate: "",
//     birthDate: "",
//     residentialStatus: "",
//     percentShares: "",
//     contactType: "",
//     contactCountry: "",
//     contactNumber: "",
//     nationality: "",
//     sourceOfFunds: "",
//     occupation: "",
//   });

//   const [errors, setErrors] = useState({
//     fullName: "",
//     idType: "",
//     country: "",
//     number: "",
//     issueDate: "",
//     expiryDate: "",
//     birthDate: "",
//     residentialStatus: "",
//     contactType: "",
//     contactCountry: "",
//     contactNumber: "",
//     nationality: "",
//     sourceOfFunds: "",
//     occupation: "",
//   });

//   const handleDropdownChange = (event) => {
//     console.log(event.target);
//     const { name, value } = event.target;
//     setCustomerData((prevFormValues) => ({
//       ...prevFormValues,
//       [name]: value,
//     }));
//     console.log(name + ";" + value);
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setCustomerData((prevState) => ({
//       ...prevState,
//       [name]: value,
//     }));
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: "",
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     console.log("Submit button clicked");

//     // Check if any required fields are empty
//     const requiredFields = [
//       "fullName",
//       "idType",
//       "country",
//       "number",
//       "issueDate",
//       "expiryDate",
//       "birthDate",
//       "residentialStatus",
//       "contactType",
//       "contactCountry",
//       "contactNumber",
//       "sourceOfFunds",
//       "occupation",
//     ];
//     const newErrors = {};
//     let hasErrors = false;

//     requiredFields.forEach((fieldName) => {
//       if (!customerData[fieldName]) {
//         newErrors[fieldName] = "This field is required.";
//         hasErrors = true;
//       }
//     });

//     if (hasErrors) {
//       setErrors(newErrors);
//       console.log("Validation errors:", newErrors);
//       return;
//     }

//     const data = {
//       fullLegalName: customerData.fullName,
//       idDoc: {
//         type: customerData.idType,
//         issueCountry: customerData.country,
//         number: customerData.number,
//         issueDate: customerData.issueDate,
//         expiryDate: customerData.expiryDate,
//       },
//       doB: customerData.birthDate,
//       nationality: customerData.country,
//       residentialStatus: customerData.residentialStatus,
//       contactInfo: {
//         type: customerData.contactType,
//         country: customerData.contactCountry,
//         number: customerData.contactNumber,
//       },
//       occupation: customerData.occupation,
//       sourceOfFunds: customerData.sourceOfFunds,
//     };

//     console.log("Data to be submitted:", data);

//     try {
//       const response = await postIndividual(data).unwrap();
//       console.log("Response from server:", response);
//       onClose();
//       setCustomerData({
//         fullName: "",
//         idType: "",
//         country: "",
//         number: "",
//         issueDate: "",
//         expiryDate: "",
//         birthDate: "",
//         residentialStatus: "",
//         contactType: "",
//         contactCountry: "",
//         contactNumber: "",
//         sourceOfFunds: "",
//         occupation: "",
//       });
//     } catch (error) {
//       console.error("Error submitting data:", error);
//     }
//   };

//   // const handleSubmit = (event) => {
//   //   event.preventDefault();

//   //   // Check if any required fields are empty
//   //   const requiredFields = [
//   //     "fullName",
//   //     "idType",
//   //     "country",
//   //     "number",
//   //     "issueDate",
//   //     "expiryDate",
//   //     "birthDate",
//   //     "residentialStatus",
//   //     "contactType",
//   //     "contactCountry",
//   //     "contactNumber",
//   //     "sourceOfFunds",
//   //     "occupation",
//   //   ];
//   //   const newErrors = {};
//   //   let hasErrors = false;

//   //   requiredFields.forEach((fieldName) => {
//   //     if (!customerData[fieldName]) {
//   //       newErrors[fieldName] = "This field is required.";
//   //       hasErrors = true;
//   //     }
//   //   });

//   //   if (hasErrors) {
//   //     setErrors(newErrors);
//   //     return;
//   //   }

//   //   const data = {
//   //     fullLegalName: customerData.fullName,
//   //     idDoc: {
//   //       type: customerData.idType,
//   //       issueCountry: customerData.country,
//   //       number: customerData.number,
//   //       issueDate: customerData.issueDate,
//   //       expiryDate: customerData.expiryDate,
//   //     },
//   //     doB: customerData.birthDate,
//   //     nationality: customerData.country,
//   //     residentialStatus: customerData.residentialStatus,
//   //     contactInfo: {
//   //       type: customerData.contactType,
//   //       country: customerData.contactCountry,
//   //       number: customerData.contactNumber,
//   //     },
//   //     occupation: customerData.occupation,
//   //     sourceOfFunds: customerData.sourceOfFunds,
//   //   };

//   //   // Perform any additional logic or validation here before using the data object

//   //   // Do something with the data object
//   //   console.log(data);
//   //   postIndividual(data);
//   //   onClose();
//   //   setCustomerData({
//   //     fullName: "",
//   //     idType: "",
//   //     country: "",
//   //     number: "",
//   //     issueDate: "",
//   //     expiryDate: "",
//   //     birthDate: "",
//   //     residentialStatus: "",
//   //     contactType: "",
//   //     contactCountry: "",
//   //     contactNumber: "",
//   //   });
//   // };

//   return (
//     <div>
//       <Dialog
//         open={open}
//         onClose={onClose}
//         fullWidth
//         maxWidth="md"
//         scroll="paper"
//       >
//         <DialogTitle>Add New Customer</DialogTitle>
//         <DialogContent>
//           <Grid
//             container={true}
//             columnSpacing={3}
//             columns={{ xs: 4, sm: 8, md: 12 }}
//             padding="20px"
//             paddingTop={0}
//           >
//             <Grid item xs={3}>
//               <TextField
//                 name="fullName"
//                 label="Full Name"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.fullName}
//                 onChange={handleChange}
//                 error={!!errors.fullName}
//                 helperText={errors.fullName}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <InputLabel>ID Type</InputLabel>
//               <Select
//                 name="idType"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.idType}
//                 onChange={handleChange}
//                 error={!!errors.idType}
//                 helperText={errors.idType}
//               >
//                 <MenuItem value="ID">ID</MenuItem>
//                 <MenuItem value="Passport">Passport</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={3}>
//               <InputLabel>Country</InputLabel>
//               <Select
//                 name="country"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.country}
//                 onChange={handleChange}
//                 error={!!errors.country}
//                 helperText={errors.country}
//               >
//                 {countryMenuItems}
//               </Select>
//             </Grid>
//             <Grid item xs={3}>
//               <TextField
//                 name="number"
//                 label="Number"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.number}
//                 onChange={handleChange}
//                 error={!!errors.number}
//                 helperText={errors.number}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <TextField
//                 name="issueDate"
//                 label="Issue Date"
//                 type="date"
//                 fullWidth
//                 margin="normal"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 value={customerData.issueDate}
//                 onChange={handleChange}
//                 error={!!errors.issueDate}
//                 helperText={errors.issueDate}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <TextField
//                 name="expiryDate"
//                 label="Expiry Date"
//                 type="date"
//                 fullWidth
//                 margin="normal"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 value={customerData.expiryDate}
//                 onChange={handleChange}
//                 error={!!errors.expiryDate}
//                 helperText={errors.expiryDate}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <TextField
//                 name="birthDate"
//                 label="Birth Date"
//                 type="date"
//                 fullWidth
//                 margin="normal"
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 value={customerData.birthDate}
//                 onChange={handleChange}
//                 error={!!errors.birthDate}
//                 helperText={errors.birthDate}
//               />
//             </Grid>
//             <Grid item xs={3}>
//               <InputLabel>Residential Status</InputLabel>
//               <Select
//                 name="residentialStatus"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.residentialStatus}
//                 onChange={handleChange}
//                 error={!!errors.residentialStatus}
//                 helperText={errors.residentialStatus}
//               >
//                 <MenuItem value="Resident">Resident</MenuItem>
//                 <MenuItem value="National">Non-Resident</MenuItem>
//               </Select>
//             </Grid>

//             <Grid item xs={3}>
//               <InputLabel>Occupation</InputLabel>
//               <Select
//                 name="occupation"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.occupation}
//                 onChange={handleChange}
//                 error={!!errors.occupation}
//                 helperText={errors.occupation}
//               >
//                 {businessActivityMenuItems}
//               </Select>
//             </Grid>
//             {/* <Grid item xs={3}>
//               <InputLabel>Occupation </InputLabel>
//               <Select
//                 name="occuption"
//                 value={customerData.occupation}
//                 onChange={handleChange}
//                 fullWidth
//                 margin="normal"
//               >
//                 {businessActivityMenuItems}
//               </Select>
//             </Grid> */}
//             <Grid item xs={3}>
//               <InputLabel>Source of Funds</InputLabel>{" "}
//               <SourceOfFunds
//                 sourceOfFunds={customerData.sourceOfFunds ?? ""}
//                 onChange={handleDropdownChange}
//               />
//             </Grid>

//             <Grid item xs={12}>
//               <Typography variant="h6">Contact Info</Typography>
//             </Grid>
//             <Grid item xs={4}>
//               <InputLabel>Contact Type</InputLabel>
//               <Select
//                 name="contactType"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.contactType}
//                 onChange={handleChange}
//                 error={!!errors.contactType}
//                 helperText={errors.contactType}
//               >
//                 <MenuItem value="Telephone">Phone</MenuItem>
//                 <MenuItem value="Mobile">Mobile</MenuItem>
//               </Select>
//             </Grid>
//             <Grid item xs={4}>
//               <InputLabel>Contact Country</InputLabel>
//               <Select
//                 name="contactCountry"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.contactCountry}
//                 onChange={handleChange}
//                 error={!!errors.contactCountry}
//                 helperText={errors.contactCountry}
//               >
//                 {countryMenuItems}
//               </Select>
//             </Grid>

//             <Grid item xs={4}>
//               <TextField
//                 name="contactNumber"
//                 label="Contact Number"
//                 fullWidth
//                 margin="normal"
//                 value={customerData.contactNumber}
//                 onChange={handleChange}
//                 type="number"
//                 inputProps={{
//                   min: 0,
//                   maxLength: 5,
//                   inputMode: "numeric",
//                   pattern: "[0-9]*",
//                 }}
//                 error={!!errors.contactNumber}
//                 helperText={errors.contactNumber}
//               />
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={onClose} color="secondary" variant="contained">
//             Cancel
//           </Button>
//           <Button onClick={handleSubmit} color="primary" variant="contained">
//             Save
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default AddCustomer;

// const SourceOfFunds = ({ sourceOfFunds, onChange }) => {
//   const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

//   if (isLoadingCountries) {
//     return <div>Loading...</div>;
//   }

//   const handleAutocompleteChange = (event, newValue) => {
//     // Directly call the passed onChange function with the correct format
//     onChange({
//       target: {
//         name: "sourceOfFunds",
//         value: newValue ? newValue.source : "",
//       },
//     });
//   };

//   // Find the option that matches the current value
//   const currentValue = data.sourceOfFundsScores.find(
//     (option) => option.source === sourceOfFunds
//   );

//   return (
//     <div>
//       <Autocomplete
//         value={currentValue}
//         required
//         onChange={handleAutocompleteChange}
//         options={data.sourceOfFundsScores}
//         getOptionLabel={(option) => option.source}
//         renderInput={(params) => <TextField required {...params} />}
//       />
//     </div>
//   );
// };

import React, { useState, useEffect } from "react";
import {
  Box,
  Fab,
  FormControlLabel,
  Button,
  Checkbox,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  InputLabel,
  Select,
  DialogTitle,
  Typography,
  DialogContent,
  InputAdornment,
  Divider,
  Autocomplete,
} from "@mui/material";

import { usePostIndividualsMutation, useGetCountriesQuery } from "state/api";

import MenuItem from "@mui/material/MenuItem";
import { countryNames, businessActivityScores } from "data/data";
import SearchSimple from "./SearchSimple";
const storedValue = JSON.parse(localStorage.getItem("DashBoardUser"));
const userId = storedValue?._id;

const countryMenuItems = countryNames.map((country) => (
  <MenuItem value={`${country}`} key={country}>
    {country}
  </MenuItem>
));

const businessActivityMenuItems = businessActivityScores.map((activity) => (
  <MenuItem value={`${activity.Name}`} key={activity.Name}>
    {activity.Name}
  </MenuItem>
));
// Ensure this path is correct

const AddCustomer = ({ open, onClose }) => {
  const [postIndividual] = usePostIndividualsMutation();
  const [fields, setFields] = useState([]);
  const [showSearchFullName, setShowSearchFullName] = useState(false);
  const [status, setStatus] = useState("");

  const [customerData, setCustomerData] = useState({
    fullName: "",
    idType: "",
    country: "",
    nationality: "",
    number: "",
    issueDate: "",
    expiryDate: "",
    birthDate: "",
    residentialStatus: "",
    percentShares: "",
    contactType: "",
    contactCountry: "",
    contactNumber: "",
    nationality: "",
    sourceOfFunds: "",
    occupation: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    idType: "",
    country: "",
    nationality: "",
    number: "",
    issueDate: "",
    expiryDate: "",
    birthDate: "",
    residentialStatus: "",
    contactType: "",
    contactCountry: "",
    contactNumber: "",
    nationality: "",
    sourceOfFunds: "",
    occupation: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (customerData.fullName === "" && value.trim() === "") {
      setStatus("");
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleDropdownChange = (event) => {
    console.log(event.target);
    const { name, value } = event.target;
    setCustomerData((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
    console.log(name + ";" + value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submit button clicked");

    const requiredFields = [
      "fullName",
      "idType",
      "country",
      "nationality",
      "number",
      "issueDate",
      "expiryDate",
      "birthDate",
      "residentialStatus",
      "contactType",
      "contactCountry",
      "contactNumber",
      "sourceOfFunds",
      "occupation",
    ];
    const newErrors = {};
    let hasErrors = false;

    requiredFields.forEach((fieldName) => {
      if (!customerData[fieldName]) {
        newErrors[fieldName] = "This field is required.";
        hasErrors = true;
      }
    });
    setStatus("");
    if (hasErrors) {
      setErrors(newErrors);
      console.log("Validation errors:", newErrors);
      return;
    }

    const data = {
      fullLegalName: customerData.fullName,
      idDoc: {
        type: customerData.idType,
        issueCountry: customerData.country,
        number: customerData.number,
        issueDate: customerData.issueDate,
        expiryDate: customerData.expiryDate,
      },
      doB: customerData.birthDate,
      nationality: customerData.nationality,
      residentialStatus: customerData.residentialStatus,
      contactInfo: {
        type: customerData.contactType,
        country: customerData.contactCountry,
        number: customerData.contactNumber,
      },
      occupation: customerData.occupation,
      sourceOfFunds: customerData.sourceOfFunds,
    };

    console.log("Data to be submitted:", data);

    try {
      const response = await postIndividual(data).unwrap();
      console.log("Response from server:", response);
      onClose();
      setCustomerData({
        fullName: "",
        idType: "",
        country: "",
        number: "",
        nationality: "",
        issueDate: "",
        expiryDate: "",
        birthDate: "",
        residentialStatus: "",
        contactType: "",
        contactCountry: "",
        contactNumber: "",
        sourceOfFunds: "",
        occupation: "",
      });
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        scroll="paper"
      >
        <DialogTitle>Add New Customer</DialogTitle>
        <DialogContent>
          <Grid
            container
            columnSpacing={3}
            columns={{ xs: 4, sm: 8, md: 12 }}
            padding="20px"
            paddingTop={0}
          >
            {showSearchFullName ? (
              <SearchSimple
                name={customerData.fullName}
                type={"Individual"}
                setStatus={setStatus}
                setShowSearch={setShowSearchFullName}
              />
            ) : (
              <Grid item xs={3}>
                <InputLabel>Full Name</InputLabel>
                <TextField
                  name="fullName"
                  fullWidth
                  margin="none"
                  padding="none"
                  value={customerData.fullName}
                  onChange={handleChange}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {status === "" ? (
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setShowSearchFullName(true)}
                            disabled={customerData.fullName.length <= 4}
                          >
                            SCREEN
                          </Button>
                        ) : (
                          <span
                            style={{
                              color:
                                status === "System cleared" ||
                                status === "Cleared by notes"
                                  ? "green"
                                  : "red",
                              fontSize: "8px",
                            }}
                          >
                            {status}
                          </span>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={3}>
              <InputLabel>ID Type</InputLabel>
              <Select
                name="idType"
                fullWidth
                margin="normal"
                value={customerData.idType}
                onChange={handleChange}
                error={!!errors.idType}
                helperText={errors.idType}
              >
                <MenuItem value="Emirates ID">Emirates ID</MenuItem>
                <MenuItem value="Passport">Passport</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Nationality</InputLabel>
              <Select
                name="nationality"
                fullWidth
                margin="normal"
                value={customerData.nationality}
                onChange={handleChange}
                error={!!errors.country}
                helperText={errors.country}
              >
                {countryMenuItems}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel> Issue Country</InputLabel>
              <Select
                name="country"
                fullWidth
                margin="normal"
                value={customerData.country}
                onChange={handleChange}
                error={!!errors.country}
                helperText={errors.country}
              >
                {countryMenuItems}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <InputLabel>ID Number</InputLabel>

              <TextField
                name="number"
                fullWidth
                margin="none"
                value={customerData.number}
                onChange={handleChange}
                error={!!errors.number}
                helperText={errors.number}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Isuue Date</InputLabel>

              <TextField
                name="issueDate"
                type="date"
                fullWidth
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                value={customerData.issueDate}
                onChange={handleChange}
                error={!!errors.issueDate}
                helperText={errors.issueDate}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Expiry Date</InputLabel>
              <TextField
                name="expiryDate"
                type="date"
                fullWidth
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                value={customerData.expiryDate}
                onChange={handleChange}
                error={!!errors.expiryDate}
                helperText={errors.expiryDate}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Birth Date</InputLabel>
              <TextField
                name="birthDate"
                type="date"
                fullWidth
                margin="none"
                InputLabelProps={{
                  shrink: true,
                }}
                value={customerData.birthDate}
                onChange={handleChange}
                error={!!errors.birthDate}
                helperText={errors.birthDate}
              />
            </Grid>
            <Grid item xs={3}>
              <InputLabel>Residential Status</InputLabel>
              <Select
                name="residentialStatus"
                fullWidth
                margin="normal"
                value={customerData.residentialStatus}
                onChange={handleChange}
                error={!!errors.residentialStatus}
                helperText={errors.residentialStatus}
              >
                <MenuItem value="Resident">Resident</MenuItem>
                <MenuItem value="Non-Resident">Non-Resident</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={3}>
              <InputLabel>Occupation</InputLabel>
              <Select
                name="occupation"
                fullWidth
                margin="normal"
                value={customerData.occupation}
                onChange={handleChange}
                error={!!errors.occupation}
                helperText={errors.occupation}
              >
                {businessActivityMenuItems}
              </Select>
            </Grid>
            {/* <Grid item xs={3}>
              <InputLabel>Occupation </InputLabel>
              <Select
                name="occuption"
                value={customerData.occupation}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                {businessActivityMenuItems}
              </Select>
            </Grid> */}
            <Grid item xs={3}>
              <InputLabel>Source of Funds</InputLabel>{" "}
              <SourceOfFunds
                error={!!errors.sourceOfFunds}
                helperText={errors.sourceOfFunds}
                sourceOfFunds={customerData.sourceOfFunds ?? ""}
                onChange={handleDropdownChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Contact Info</Typography>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Contact Type</InputLabel>
              <Select
                name="contactType"
                fullWidth
                margin="normal"
                value={customerData.contactType}
                onChange={handleChange}
                error={!!errors.contactType}
                helperText={errors.contactType}
              >
                <MenuItem value="Telephone">Phone</MenuItem>
                <MenuItem value="Mobile">Mobile</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <InputLabel>Contact Country</InputLabel>
              <Select
                name="contactCountry"
                fullWidth
                margin="normal"
                value={customerData.contactCountry}
                onChange={handleChange}
                error={!!errors.contactCountry}
                helperText={errors.contactCountry}
              >
                {countryMenuItems}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <InputLabel>Contact Number</InputLabel>
              <TextField
                name="contactNumber"
                fullWidth
                margin="none"
                value={customerData.contactNumber}
                onChange={handleChange}
                type="number"
                inputProps={{
                  min: 0,
                  maxLength: 5,
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                error={!!errors.contactNumber}
                helperText={errors.contactNumber}
              />
            </Grid>
            {/* Add other fields here */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddCustomer;

const SourceOfFunds = ({ sourceOfFunds, onChange }) => {
  const { data, isLoading: isLoadingCountries } = useGetCountriesQuery(userId);

  if (isLoadingCountries) {
    return <div>Loading...</div>;
  }

  const handleAutocompleteChange = (event, newValue) => {
    // Directly call the passed onChange function with the correct format
    onChange({
      target: {
        name: "sourceOfFunds",
        value: newValue ? newValue.source : "",
      },
    });
  };

  // Find the option that matches the current value
  const currentValue = data?.sourceOfFundsScores?.find(
    (option) => option.source === sourceOfFunds
  );

  return (
    <div>
      <Autocomplete
        value={currentValue}
        required
        error={!!sourceOfFunds.error}
        helperText={sourceOfFunds.error}
        onChange={handleAutocompleteChange}
        options={data?.sourceOfFundsScores}
        getOptionLabel={(option) => option.source}
        renderInput={(params) => <TextField required {...params} />}
      />
    </div>
  );
};
